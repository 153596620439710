import Jonathan from "../../assets/jonathan.png";
import Katryn from "../../assets/katryn.png";
// import Image3 from "../../assets/testimonial3.png";

export const Data = [
    {
      id: 1,
      image: Jonathan,
      title: "Jonathan Bossenger",
      jobTitle: "Developer Educator",
      company: "Automattic",
      description:
        "Since joining the WordPress training team, Muhibul has been a valuable collaborator. From creating video content, co-hosting and hosting workshops, translating content, administrative tasks, and mentoring new contributors, Muhibul has been a force multiplier within the team. I always know that if I ask him to do something, it will get done.",
    },
    {
      id: 2,
      image: Katryn,
      title: "Kathryn Presner",
      jobTitle: "Community Education Manager",
      company: "Automattic",
      description:
        "I’ve enjoyed collaborating with Muhibul on the WordPress.org Training Team since I got involved in March 2024. He’s diligent about performing tasks he’s taken on, whether mentoring new contributors or handling administrative work essential to the functioning of the team. I’m consistently impressed with his work and greatly value how he’s stepped forward to take on various projects when I’ve asked for help. I also really appreciate his ability to follow through and communicate consistently.",
    },
    // {
    //   id: 3,
    //   image: Image3,
    //   title: "Sara Cill",
    //   description:
    //     "A really good job, all aspects of the project were followed step by step and with good results.",
    // },
  ];