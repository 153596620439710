import './testimonial.css'
import {Data} from './Data'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
export default function Testimonial(){
    return(
        <section className="testimonial container section">
            <h2 className="section__title">What People Say About Me!</h2>
            <span className="section__subtitle">Testimonials</span>

            <Swiper 
                className="testimonial__container"
                loop={true}
                grabCursor={true}
                spaceBetween={24}
                pagination={{
                clickable: true,
                }}
                breakpoints={{
                576: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 48,
                },
                }}
                modules={[Pagination]}
            >
                {Data.map(({id, image, title, jobTitle,company, description}) => {
                    return(
                        <SwiperSlide 
                            className="testimonial__card" 
                            key={id}
                        >
                            <img src={image} alt={title} className='testimonial__img' />
                            <h3 className="testimonial__name">
                                {title}
                            </h3>
                            <div className='testimonial__meta'><strong>{jobTitle}</strong> ({company})</div>
                            <p className="testimonial__description">
                                {description}
                            </p>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </section>
    )
}
